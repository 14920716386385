@use './breakpoints' as bp;

@mixin sectionStyles {

	&>div {
		@include bnpp-container;
	}

	.content-col {
		text-align: center;
		max-width: 400px;
		margin: 0 auto;
	}

	&.dark {
		@include darkStyles;
	}

	&.green {
		@include greenStyles;
	}

	&.charcoal {
		@include charcoalStyles;
	}

	&:is(.dark, .green, .charcoal) {

		* {
			color: var(--wp--preset--color--white);
		}

		&.contained {
			background-color: var(--wp--preset--color--dark-green);
			padding: var(--wp--preset--spacing--60);
			
			*:not(path):not(.bnpp-button):not(.button-icon) {
				color: var(--wp--preset--color--bnpp-black);
			}
	
			&>div {
				background-color: var(--wp--preset--color--white);
			}

			h2, p {
				color: var(--wp--preset--color--bnpp-black);
			}
		}
	}

	&.contained {
		background-color: var(--wp--preset--color--white);
		padding: var(--wp--preset--spacing--60);

		* {
			color: var(--wp--preset--color--white);
		}

		&>div {
			background-color: var(--wp--preset--color--dark-green);
		}
	}

	&.full-width {
        max-width: none;
		width: 100%;
		padding: 0;
    }

}

@mixin grid($gridSize: 300px, $fit: auto-fit, $gap: var(--wp--preset--spacing--50)){
    display: grid;
    grid-template-columns: repeat($fit, minmax(min(100%, $gridSize), 1fr));
    gap: $gap;
}

@mixin bnpp-container {
    max-width: var(--wp--style--global--wide-size);
    width: 80%;
    margin: 0 auto;
    padding: var(--wp--preset--spacing--60) 0;
	
	@media only screen and (max-width: bp.$breakpoint-medium) {
		padding: var(--wp--preset--spacing--50) 0;
	}

    &.narrow {
        max-width: var(--wp--style--global--content-size)!important;
    }

    &.full-width {
        max-width: none!important;
		width: 100%!important;
    }
}

@mixin darkStyles {
    background: none;
    background-color: var(--wp--preset--color--dark-green);
}

@mixin greenStyles {
    background: none;
    background-color: var(--wp--preset--color--bnpp-green);
}

@mixin charcoalStyles {
    background: none;
    background-color: var(--wp--preset--color--charcoal);
}

@mixin button {
    --buttonHoverColour: #004E31;
    background-color: var(--wp--preset--color--bnpp-green);
    padding: var(--wp--preset--spacing--40) var(--wp--preset--spacing--60);
    color: var(--wp--preset--color--white);
    font-size: var(--wp--preset--font-size--medium);
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: 300ms;
    display: inline-flex;
    align-items: center;
    width: fit-content;
    gap: var(--wp--preset--spacing--40);
    position: relative; 

    &.secondary {
        --buttonHoverColour: var(--wp--preset--color--bnpp-green);
        background-color: var(--wp--preset--color--bnpp-black);
    }

    &.tertiary {
        --buttonHoverColour: var(--wp--preset--color--bnpp-green);
        background-color: transparent;
        color: var(--wp--preset--color--bnpp-black);
        outline: solid 1px var(--wp--preset--color--bnpp-black);

        &:hover {
            color: var(--wp--preset--color--white);
        }
    }

    &:hover,
    &:focus {
        background-color: var(--buttonHoverColour);

		span.button-icon {
			transform: translateX(5px);
		}
    }

	&:focus {
        outline: 2px solid var(--wp--preset--color--white);
        outline-offset: 2px;
    }

    &:focus-visible {
        outline: 2px solid var(--wp--preset--color--white);
        outline-offset: 2px;
    }

    &[aria-disabled="true"] {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    span.button-icon {
        display: inline-block;
        --iconSize: 20px;
        width: var(--iconSize);
        background-color: currentColor;
        height: 2.3px;
        border-radius: 50px;
		transition: 300ms;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: calc(var(--iconSize) / 1.4);
            height: 3px;
            background: currentColor;
            border-radius: 50px;
            transform-origin: right;
        }

        &::before {
            transform: translateX(5.5px) translateY(0.5px) rotate(45deg);
        }

        &::after {
            transform: translateX(5.5px) translateY(-0.5px) rotate(-45deg);
        }
    }


}

@mixin input {
    padding: 1em 0;
    border: none;
    border-bottom: solid 1px;
}

@mixin SVGDarkBg {
	svg :is(*[style*="1d1d1b"], *[style*="rgb(29, 29, 27)"]) {
		stroke: var(--wp--preset--color--white)!important;
	}
}

@mixin hoverKeyframes {

	--animationDuration: 1s;
    --animationEasing: ease-in-out;
    --animationFillMode: forwards;

	@keyframes rotate45reverse {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(45deg);
		}
	}
	
	@keyframes rotate45 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-45deg);
		}
	}
	
	@keyframes rotate180reverse {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(180deg);
		}
	}
	
	@keyframes rotate180 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(-180deg);
		}
	}
	
	@keyframes translateY10 {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(10px);
		}
	}
	
	@keyframes translateY10reverse {
		0% {
			transform: translateY(0);
		}
		100% {
			transform: translateY(-10px);
		}
	}
	
	@keyframes translateX10 {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(10px);
		}
	}
	
	@keyframes translateX10reverse {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(-10px);
		}
	}
	
	@keyframes scale12 {
		0% {
			transform: scale(1);
		}
		100% {
			transform: scale(1.2);
		}
	}

	:is(
        [data-name="rotate45"], 
        [data-name="rotate45reverse"],
        [data-name="rotate180"],
        [data-name="rotate180reverse"],
        [data-name="translateY10"],
        [data-name="translateY10reverse"],
        [data-name="translateX10"],
        [data-name="translateX10reverse"],
        [data-name="scale12"]
    ) {
        transform-origin: center;
        transform-box: fill-box;
    }

	// Individual Icon updates
	#ngx-icon-speedometer [data-name="rotate45reverse"] {
		transform-origin: 100% 83%;
	}

	#ngx-icon-turbine [data-name="rotate180reverse"] {
		transform-origin: 50% 65%;
	}
	
}

@mixin hoverAnimationsStart {
	[data-name="rotate45"] {
		animation: rotate45 var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="rotate45reverse"] {
		animation: rotate45reverse var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="rotate180"] {
		animation: rotate180 var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="rotate180reverse"] {
		animation: rotate180reverse var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="translateY10"] {
		animation: translateY10 var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="translateY10reverse"] {
		animation: translateY10reverse var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="translateX10"] {
		animation: translateX10 var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="translateX10reverse"] {
		animation: translateX10reverse var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

	[data-name="scale12"] {
		animation: scale12 var(--animationDuration) var(--animationFillMode) var(--animationEasing);
	}

}

@mixin graphicKeyframes {
	--animationDuration: 10s;
    --animationEasing: linear;
    --animationFillMode: infinite;

	@keyframes rotate360loop {
		100% {
			transform: rotate(360deg);
		}
	}

	[data-name="rotate360loop"] {
		animation: rotate360loop var(--animationDuration) var(--animationFillMode) var(--animationEasing);
		transform-box: fill-box;
		transform-origin: 50% 66.1%;
	}

	[data-name="rotate360loop"] + [data-name="rotate360loop"] {
		--animationDuration: 15s;
	}
}