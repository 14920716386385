@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;

.image-grid-module  {

    figure {

        @include mixins.grid(100px, auto-fit, var(--wp--preset--spacing--40));

    }
    
}

a.image-grid-module {
    position: relative;
    text-decoration: none;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
        opacity: 0;
        border: solid 4px var(--wp--preset--color--bnpp-green);
        transition: 300ms;
    }

    &:hover {

        &::after {
            opacity: 1;
        }

    }
}